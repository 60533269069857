/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/


// Presets & Variables
@import 'presets/variables';
@import 'presets/mixins';
@import 'presets/grid-settings';
@import 'presets/helper-classes';

// Normalize
@import 'vendor/normalize';

// Bourbon
// @import 'vendor/bourbon/bourbon';

// Neat
// @import 'vendor/neat/neat';

// Layout
@import 'layout/homepage';

// Components
@import 'components/featured-content';

// Modules
@import 'modules/news/featured';
@import 'modules/events/featured';
