.featured-content {
  background: $merino;
  font-family: $georgia;
  font-size: 16px;
  line-height: 24px;

  h2 {
    position: relative;
    display: block;
    padding: 0;
    margin: 0 0 18px;
    font-family: $franklin-gothic;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.25px;
    color: $merlin;
    text-transform: uppercase;
    border-bottom: 1px solid rgba($black, 0.2);

    span {
      position: relative;
      bottom: -7px;
      display: inline-block;
      padding: 0 16px 0 0;
      background: $merino;
    }
  }

  h3 {
    padding: 0;
    margin: 0 0 14px;
    font-family: $franklin-gothic-cond;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: $resolution-blue;

    a {
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      color: inherit;
      text-transform: inherit;
      text-decoration: none;
    }
  }

  p {
    padding: 0;
    margin: 0 0 12px;
  }

  a {
    font-family: $franklin-gothic;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: $rosewood;
    text-decoration: none;
    text-transform: uppercase;
  }

  img {
    max-width: 150px;
    float: left;
    margin-right: 15px;
  }
}


// .featured-content {
//   position: relative;
//   height: 142px;
//   padding: 7px 0;
//   background-image: url('../images/ucci-book-mobile.png');
//   background-repeat: no-repeat;
//   background-position: 100% 50%;
//   border-bottom: 1px solid $silver;

//   @include clearfix;

//   @include respond-to($venti) {
//     // display: none;
//     background-image: url('../images/ucci-book-full.png');
//     background-position: 0 0;
//     padding: 0;
//     height: 399px;
//   }

//   .blurb {
//     position: relative;
//     top: 50%;
//     z-index: 2;
//     width: 65%;
//     padding: 0 20px;
//     transform: translateY(-50%);

//     @include respond-to($venti) {
//       padding-right: 60px;
//       padding-left: 400px;
//       width: 100%;
//     }
//   }

//   h2 {
//     padding: 0;
//     margin: 0 0 14px;
//     font-family: $franklin-gothic-cond;
//     font-size: 21px;
//     font-weight: 700;
//     line-height: 24px;
//     color: $resolution-blue;

//     @include respond-to($venti) {
//       font-size: 36px;
//       line-height: 36px;
//     }
//   }

//   p {
//     display: none;

//      @include respond-to($venti) {
//       display: block;
//       font-family: $georgia;
//       font-size: 21px;
//       line-height: 36px;
//     }
//   }

//   .jump {
//     font-family: $franklin-gothic;
//     font-size: 14px;
//     font-weight: 700;
//     line-height: 24px;
//     color: $rosewood;
//     text-decoration: none;
//     text-transform: uppercase;

//     @include respond-to($venti) {
//       font-size: 18px;
//       line-height: 36px;
//     }
//   }
// }
