.featured-events {
  display: block;
  // background: $merino;
  background: $white;

  h2 {
    position: relative;
    display: block;
    padding: 0;
    margin: 0 0 18px;
    font-family: $franklin-gothic;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.25px;
    color: $merlin;
    text-transform: uppercase;
    border-bottom: 1px solid rgba($black, 0.2);

    span {
      position: relative;
      bottom: -7px;
      display: inline-block;
      padding: 0 16px 0 0;
      background: $white;
    }
  }

  ul {
    padding: 0;
    margin: 0 0 8px;
    list-style: none;
    border-bottom: 3px solid rgba($black, 0.2);
  }

  li {
    display: flex;
    padding: 10px 0;

    // @include clearfix;
    &::after {
      clear: both;
      content: "";
      display: table;
    }
  }

  li + li {
    border-top: 1px solid rgba($black, 0.2);
  }

  a {
    color: $rosewood;
  }


  .event-date {
    float: left;
    width: 49px;
    padding: 3px 10px 2px 0;
    font-family: $franklin-gothic-cond;
    font-size: 15px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: -0.5px;
    color: $merlin;
    text-align: right;
  }

  .event-title {
    float: left;
    width: calc(100% - 50px);
    padding: 3px 10px 2px;
    font-family: $georgia;
    font-size: 16px;
    font-style: italic;
    line-height: 16px;
    text-decoration: none;
    border-left: 1px solid rgba($black, 0.2);
  }

  .view-all {
    font-family: $franklin-gothic;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: $rosewood;
    text-decoration: none;
    text-transform: uppercase;
  }
}
