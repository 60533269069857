// Breakpoints
$small: 480px;
$medium: 768px;
$large: 960px;

// Fonts
$franklin-gothic: 'Roboto', sans-serif;
$franklin-gothic-cond: 'Roboto Condensed', sans-serif;
$georgia: Georgia, serif;
$times: 'Times new Roman', serif;
$font-awesome: 'FontAwesome';

// Grayscale
$white: #ffffff;
$wild-sand: #f5f5f5;
$gallery: #ebebeb;
$mercury: #e5e5e5;
$silver: #cccccc;
$scorpion: #595959;
$black: #000000;

// Reds
$rosewood: #6b000e;
$temptress: #450009;
$error: #D8000C;

// Yellows
$frangipani: #feddb5;
$spring-wood: #faf8f5;
$peach-yellow: #faddb5;
$merino: #f8f3ec;
$pearl-bush: #ede7df;
$cotton-seed: #c2bdb6;
$merlin: #42413e;

// Blues
$polo-blue: #91a1cf;
$chambray: #3d5597;
$bali-hai: #7e89a8;
$resolution-blue: #002076;
$stratos: #00154f;

// Brand Colors
$brand-primary: $resolution-blue;
$brand-secondary: $peach-yellow;
$brand-accent: $rosewood;
