.homepage {
  section.main {
    margin-top: 20px;
    display: flex;    
    flex-direction: column-reverse;
    width: 100%;
    
    @include large-up {
      flex-direction: row;
    }

    .card {
      margin-bottom: 40px;
      margin-top: 0;
      padding: 20px 35px 20px;
      width: 100%;
    }
  }

  .main-content {
    @include large-up {
      display: flex;      
      max-width: 70%;
      flex: 0 0 70%;
    }

    .row {
      display: flex;      
      width: 100%;
      flex-wrap: wrap;

      .card {
        margin-bottom: 20px;
        // margin-top: 0;
        // padding: 20px 35px 20px;
        // width: 100%;

        @include large-up {
          &:nth-child(odd) {
            width: 58%;
            margin-right: 20px;
          }

          &:nth-child(even) {
            width: calc(42% - 20px);
          }

          &:nth-child(n + 3) {
            margin-bottom: 40px;
          }
        }
      }
    }

    .featured-image,
    .handbook {
      background: $white;
    }

    .handbook {

      h2 {
        position: relative;
        display: block;
        padding: 0;
        margin: 0 0 18px;
        font-family: $franklin-gothic;
        font-size: 22px;
        font-weight: 400;
        line-height: 1.2;
        letter-spacing: 0.25px;
        color: $merlin;
        text-transform: uppercase;
        border-bottom: 1px solid rgba($black, 0.2);

        span {
          position: relative;
          bottom: -7px;
          display: inline-block;
          padding: 0 16px 0 0;
          background: $white;
        }
      }

      a {
        font-family: $franklin-gothic;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
        color: $rosewood;
        text-decoration: none;
        text-transform: uppercase;
      }

      .inner-copy {
        @include large-up {
          border-bottom: 3px solid rgba(0, 0, 0, 0.2);
          padding-bottom: 20px;
          margin-bottom: 10px;
        }
      }

      img {
        float: left;
        width: 98px;
        margin-right: 10px;
        margin-right: 0.625rem;
        margin-left: -8px;
        margin-bottom: 20px;
      }
      

      h3 {
        padding: 0;
        margin: 0 0 14px;
        font-family: $franklin-gothic-cond;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        color: $resolution-blue;

        a {
          font-family: inherit;
          font-size: inherit;
          font-weight: inherit;
          line-height: inherit;
          color: inherit;
          text-transform: inherit;
          text-decoration: none;
        }
      }
    }

    .featured-image {
      display: none;
      @include large-up {
        display: block;
        // background-image: url('../images/ucci-home-featured-image.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 0;
        border: none;
        border-radius: 10px;
      }
    }
    

  }
  
  .sidebar {
    width: 100%;
    @include large-up {
      max-width: 280px;
      margin-right: 20px;
      display: flex;
    }

    .card {
      margin-top: 0;
    }

    .featured-news {
      @include large-up {
        // margin-right: calc(3.846% * 1);

        // width: calc(3.846% * 17);
        width: 100%;
      }
    }
  }
}
