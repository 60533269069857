.featured-news {
  display: block;
  font-family: $georgia;
  font-size: 16px;
  line-height: 24px;
  background: $white;

  h2 {
    position: relative;
    display: block;
    padding: 0;
    margin: 0 0 18px;
    font-family: $franklin-gothic;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.25px;
    color: $merlin;
    text-transform: uppercase;
    border-bottom: 1px solid rgba($black, 0.2);

    span {
      position: relative;
      bottom: -7px;
      display: inline-block;
      padding: 0 16px 0 0;
      background: $white;
    }
  }

  h3 {
    padding: 0;
    margin: 0 0 14px;
    font-family: $franklin-gothic-cond;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: $resolution-blue;

    a {
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      color: inherit;
      text-transform: inherit;
    }
  }

  ul {
    padding: 7px 0 0;
    margin: 0;
    list-style: none;

    // @include clearfix;
    &::after {
      clear: both;
      content: "";
      display: table;
    }
  }

  li {
    padding-bottom: 30px;

    @include medium-up {
      // float: left;
      // width: calc((100% - 80px) / 2);
      width: 100%;
      margin-right: 40px;
    }

    &:last-child {
      border-bottom: 3px solid rgba(0, 0, 0, 0.2);
      margin-bottom: 10px;
    }

  }

  li + li{
    margin-top: 24px;

    @include medium-up {
      margin-top: 0;
      margin-right: 0;
    }
  }


  p {
    padding: 0;
    margin: 0 0 12px;
  }

  a {
    font-family: $franklin-gothic;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: $rosewood;
    text-decoration: none;
    text-transform: uppercase;
  }

  .view-all {
    font-family: $franklin-gothic;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: $rosewood;
    text-decoration: none;
    text-transform: uppercase;
  }

  .featured-content .news-story-submit {
    position: absolute;
    bottom: 0;
    margin-bottom: 18px;
    font-size: 16px; 
  }
}
