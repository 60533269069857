// general
.element-invisible {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}

.hide {
  display: none;
}

.invisible {
  visibility: hidden;
}

.clear {
  display: block;
  clear: both;
}

.clear-after:after {
  display: table;
  clear: both;
  content: '';
}

.group:after {
  display: table;
  clear: both;
  content: '';
}

// floats
.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
  padding: 30px;
}

.float-left {
  float: left;
  padding: 30px 30px 30px 0px;
}

.float-right {
  float: right;
  padding: 30px 0px 30px 30px;
}

// alignment
.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

// attentions
.attention {
  background: cyan;
}

// visiblity classes
// uncomment if not using neat
// .show-for-medium {
//   @include respond-to('small-only') {
//     display: none !important;
//   }
// }
// .show-for-large {
//   @include respond-to('small-only') {
//     display: none !important;
//   }
//   @include respond-to('medium-only') {
//     display: none !important;
//   }
// }
// .show-for-small-only {
//   @include respond-to('medium') {
//     display: none !important;
//   }
// }
// .show-for-medium-only {
//   @include respond-to('small-only') {
//     display: none !important;
//   }
//   @include respond-to('large') {
//     display: none !important;
//   }
// }
// .show-for-large-only {
//   @include respond-to('small-only') {
//     display: none !important;
//   }
//   @include respond-to('medium-only') {
//     display: none !important;
//   }
// }
// .hide-for-medium {
//   @include respond-to('medium') {
//     display: none !important;
//   }
// }
// .hide-for-large {
//   @include respond-to('large') {
//     display: none !important;
//   }
// }
// .hide-for-medium-only {
//   @include respond-to('medium-only') {
//     display: none !important;
//   }
// }
// .hide-for-large-only {
//   @include respond-to('large-only') {
//     display: none !important;
//   }
// }

// uncomment if using neat
// .show-for-medium {
//   @include media('small-only') {
//     display: none !important;
//   }
// }
// .show-for-large {
//   @include media('small-only') {
//     display: none !important;
//   }
//   @include media('medium-only') {
//     display: none !important;
//   }
// }
// .show-for-small-only {
//   @include media('medium') {
//     display: none !important;
//   }
// }
// .show-for-medium-only {
//   @include media('small-only') {
//     display: none !important;
//   }
//   @include media('large') {
//     display: none !important;
//   }
// }
// .show-for-large-only {
//   @include media('small-only') {
//     display: none !important;
//   }
//   @include media('medium-only') {
//     display: none !important;
//   }
// }
// .hide-for-medium {
//   @include media('medium') {
//     display: none !important;
//   }
// }
// .hide-for-large {
//   @include media('large') {
//     display: none !important;
//   }
// }
// .hide-for-medium-only {
//   @include media('medium-only') {
//     display: none !important;
//   }
// }
// .hide-for-large-only {
//   @include media('large-only') {
//     display: none !important;
//   }
// }